import React, { useState } from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import BildHeader from "../../images/angebot/situationsgerechtes-sehen/header.jpg"
import BildLinks from "../../images/angebot/situationsgerechtes-sehen/links.jpg"
import BildOben from "../../images/angebot/situationsgerechtes-sehen/oben.jpg"
import BildUnten from "../../images/angebot/situationsgerechtes-sehen/unten.jpg"
import SubNavigation from "../../components/SubNavAngebot"
import Termin from "../../components/Termin"

const SituationsgerechtesSehen: React.FC = () => {
  const siteTitle = "Situationsgerechtes Sehen - Angebot"
  const greenVer = true

  const [isSubMenuOpen, setSubMenuOpen] = useState(false)
  return (
    <Layout title={siteTitle} greenVer={greenVer} background="#00aa9b">
      <SEO title={siteTitle} />
      <section className="wrapper">
        <div className="headerImage">
          <img src={BildHeader} />
        </div>
      </section>
      <Termin />
      <SubNavigation
        isSubMenuOpen={isSubMenuOpen}
        setSubMenuOpen={setSubMenuOpen}
        activeMenuText="Situationsgerechtes Sehen"
      />
      <section className="wrapper greyColor content">
        <h1>Situationsgerechtes Sehen</h1>
        <h2 className="contentSubtitle">SCHWARZ Optik – Seit 1992 in Heiden</h2>
        <div className="contentColumns">
          <div className="contentColumn">
            <div className="imageCollection">
              <div className="imageColumn" style={{ width: "calc(100%)" }}>
                <div className="imageRow" style={{ marginBottom: "0" }}>
                  <div className="imageColumn">
                    <img src={BildLinks} />
                  </div>
                  <div className="imageColumn">
                    <div className="imageRow">
                      <img src={BildUnten} />
                    </div>
                    <div className="imageRow">
                      <img src={BildOben} />
                    </div>
                  </div>
                </div>
                <p className="imageCaption"></p>
              </div>
            </div>
          </div>
          <div className="contentColumn">
            <h2>Sicherheit beim Sport und in der Freizeit</h2>
            <p>
              Brilliante Sicht und maximaler Tragekomfort sind Voraussetzungen
              für unbeschwerten und sicheren Sport. Für jede Sportart bietet
              SCHWARZ Optik in Heiden das passende Sehkonzept. Sportbrillen die
              z. B. optimalen Blendschutz und Kontraste bieten, lassen sich in
              der Regel problemlos mit den persönlichen Korrektureinsätzen
              ausrüsten, sind äusserst bequem und natürlich rutschfest.
            </p>
            <h2>Spezialität Schiessbrillen</h2>
            <p>
              Als eigentliche Spezialität pflegt SCHWARZ Optik das Marktsegment
              Schiessbrillen.
            </p>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default SituationsgerechtesSehen
